<template>
  <div class="upgrade-trip-view tw-mb-5">
    <v-container v-if="trip && sessionUser">
      <v-row>
        <v-col cols="12" class="tw-text-left">
          <h2>Hi, {{ sessionUser.firstName }}!</h2>
          <p>
            Welcome to Let’s Jetty First Class! You have upgrades available and
            can use them to upgrade any trip you’re attending.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
          <v-row align="center" class="tw-flex tw-py-3 tw-px-2 tw-items-center">
            <v-col cols="2">
              <img
                src="@/assets/svgs/premium/blue-diamond-solo.svg"
                class="tw-mr-3"
              />
            </v-col>
            <v-col cols="8">
              <span>Upgrades available</span>
            </v-col>
            <v-col cols="2" class="tw-text-right">
              <span>{{ wallet.credits }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row class="tw-text-left">
        <v-col>
          <h3 class="tw-mb-2">Confirm Trip Upgrade</h3>
          <p>
            You're about to upgrade
            <span class="j-premium-text">{{ trip.title }}</span>
          </p>
          <p>
            Upgrading will cost 1 credit and you will have
            <span class="j-premium-text"
              >{{ wallet.credits - 1 }}
              {{ wallet.credits - 1 === 1 ? "credit" : "credits" }}
              remaining.</span
            >
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Would you like to continue?</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mb-5">
          <v-btn
            class="j-premium"
            rounded
            dark
            x-large
            depressed
            block
            @click.native="upgradeTrip"
            >Yes! Upgrade this trip</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2>Enjoy these perks!</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-carousel
            class="perks-carousel"
            height="315"
            hide-delimiter-background
            :show-arrows="false"
            dark
          >
            <v-carousel-item>
              <v-sheet height="250">
                <v-col class="tw-text-left px-13 pt-10">
                  <img
                    src="@/assets/svgs/upgrade/custom-invitation.svg"
                    class="tw-mb-4"
                  />
                  <h3>Customize your invitation</h3>
                  <p class="tw-text-l-grey">
                    Choose from a custom design to spice up your trip invite.
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="250">
                <v-col class="tw-text-left px-13 pt-10">
                  <img
                    src="@/assets/svgs/upgrade/unlimited-surveys.svg"
                    class="tw-mb-4"
                  />
                  <h3>Unlimited notes & surveys</h3>
                  <p class="tw-text-l-grey">
                    Get all of your questions answered by sending out as many
                    surveys as your heart desires.
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
            <v-carousel-item>
              <v-sheet height="250">
                <v-col class="tw-text-left px-13 pt-10">
                  <img
                    src="@/assets/svgs/upgrade/be-the-first-trophy.svg"
                    class="tw-mb-4"
                  />
                  <h3>Be the first</h3>
                  <p class="tw-text-l-grey">
                    You’ll be there first to access our new premium features
                    before anyone else.
                  </p>
                </v-col>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from "luxon";
export default {
  name: "UpgradeTripView",

  data() {
    return {};
  },

  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    wallet() {
      return this.sessionUser.user_wallet;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },

  methods: {
    async upgradeTrip() {
      this.tripUpgradePending = true;
      await this.$store
        .dispatch("trip-premium-upgrade/create", {
          tripId: this.trip.id
        })
        .then(() => {
          if (localStorage.getItem("tripData")) {
            let tripData = JSON.parse(localStorage.getItem("tripData"));
            let activeDesign = localStorage.getItem("template");
            this.createInvitation(tripData, activeDesign);
            localStorage.removeItem("tripData");
            localStorage.removeItem("template");
          } else {
            this.$router.push({
              name: "TripViewRoute",
              params: { id: this.trip.id }
            });
          }
        });
      this.tripUpgradePending = false;
    },

    async createInvitation(tripData, template) {
      tripData.tripId = this.trip.id;
      if (tripData.message.length === 0)
        tripData.message = this.placeholderMessage;
      this.$store
        .dispatch("trip-invitation/create", {
          ...tripData,
          template: template,
          rsvpDeadline: DateTime.fromFormat(
            tripData.rsvpDeadline,
            "yyyy-MM-dd",
            {
              setZone: true
            }
          )
            .endOf("day")
            .toISODate()
        })
        .then((res) => {
          const now = DateTime.now();
          const start = DateTime.fromISO(this.sessionUser.createdAt);
          const diff = start.diff(now, "days").toObject();
          const count = Math.abs(Math.round(diff.days));
          let newUser = false;
          if (count <= 7) {
            newUser = true;
          }

          window.dataLayer.push({
            event: "create_trip_invite",
            uuid: this.sessionUser.uuid,
            signup_date: DateTime.fromISO(this.sessionUser.createdAt).toISO(),
            new_user: newUser,
            verified_user: this.sessionUser.isEmailVerified,
            sign_up_trip_id: this.sessionUser.signupTripId,
            page_location: localStorage.getItem("previous_route"),
            trip_invite_id: res.id,
            response_deadline: res.rsvpDeadline,
            survey_attached:
              this.trip.surveys && this.trip.surveys.length > 0 ? true : false
          });

          this.$router.push({
            name: "CrewListRoute",
            params: { uuid: res.uuid, inviteId: res.id }
          });
        })
        .catch((error) => {
          console.error("Error creating invitation:", error);
        });
    }
  },

  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "First Class",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      showBackButton: true,
      endDate: null
    });

    // Dispatch the action to get trip details
    await this.$store.dispatch("trip/get", this.$route.params.id);
    await this.$store.dispatch("users/get", this.sessionUser.id);

    if (
      !this.sessionUser.user_wallet ||
      this.sessionUser.user_wallet.credits === 0
    ) {
      this.$router.replace({
        name: "UpgradeTripPurchaseRoute",
        params: { id: this.trip.id }
      });
    }
  },
  mounted() {},
  beforeUnmount() {}
};
</script>

<style lang="scss">
.upgrade-trip-view {
  h2 {
    font-family: Figtree;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h3 {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .j-premium-text {
    font-weight: 600;
    color: #1743bb;
  }

  .perks-carousel {
    overflow: visible;

    .v-responsive {
      overflow: visible;
    }

    .v-sheet {
      border-radius: 6px;
      box-shadow: 1px 6px 19px 0px rgba(0, 0, 0, 0.11);
    }

    .v-carousel__controls {
      .v-btn > .v-btn__content .v-icon {
        color: #d9d9d9;
      }

      .v-btn.v-btn--active > .v-btn__content .v-icon {
        color: #585858;
      }
    }
  }
}
</style>
